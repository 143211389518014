<template>
    <div>
        <v-layout row pt-6>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.currency') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.sellingPrice') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.purchasePrice') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.bankCharges') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.freightInsuranceCost') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.carriageOutwards') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.commission') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.paymentTermsSellingPrice') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.latePayment') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.otherCosts') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.grossMargin') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
export default {
    name: "GrossMarginCalculation"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
    height: 32px !important;
    border-radius: 16px;
    background-color: lightgray;
    margin-top: 5px;
}
</style>